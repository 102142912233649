/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { FiInstagram, FiTwitter } from "react-icons/fi"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 80, height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
            instagram
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div className="flex flex-row flex-wrap items-center mb-8 lg:mb-20">
      <div className="flex items-center w-100 lg:w-1/6 mx-auto pb-5 lg:pb-0 px-8 lg:px-3">
        <Image
          className="rounded-full"
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
          style={{
            minWidth: 80,
          }}
        />
      </div>
      <div className="w-100 lg:w-5/6">
        <p className="px-6 lg:px-3">
          Soy ingeniero, <a href="https://unbaul.com/">me gusta escribir</a>, y ahora, gracias a la cuarentena, me puse
          a hacer pan. No sé nada pero soy porfiado. Después de varios intentos decidí registralo todo. Estos son los
          datos y los resultados están en mis cachetes.
          <span className="flex mt-2">
          <a className="mr-2 no-underline"
             href={`https://twitter.com/${social.twitter}`}
          >
            <FiTwitter/>
          </a>
          <a className="no-underline"
             href={`https://instagram.com/${social.instagram}`}
          >
            <FiInstagram/>
          </a>
        </span>
        </p>
      </div>
    </div>
  )
}

export default Bio
